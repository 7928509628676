(function ($) {
    "use strict";

    // START MENU JS
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 50) {
            $(".main-nav").addClass("menu-shrink");
        } else {
            $(".main-nav").removeClass("menu-shrink");
        }
    });

    $(".owl-carousel").owlCarousel({
        loop: false,
        margin: 20,
        items: 3,
        autoplay: true,
        nav: false,
        dotsEach: false,
        dots: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 3,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    });

    // Mean Menu
    jQuery(".mean-menu").meanmenu({
        meanScreenWidth: "991",
    });

    // Sorting Portfolio JS
    try {
        var mixer = mixitup("#container", {
            controls: {
                toggleDefault: "none",
            },
        });
    } catch (err) {}

    // Nice Select JS
    // $('select').niceSelect('update');

    // Companies Slider JS
    $(".companies-slider").owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        items: 3,
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    });

    // Profile Slider JS
    $(".profile-slider").owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        items: 3,
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    });

    // Wow JS
    new WOW().init();

    // Accordion JS
    $(".accordion > li:eq(0) a").addClass("active").next().slideDown();
    $(".accordion a").on("click", function (j) {
        var dropDown = $(this).closest("li").find("p");
        $(this).closest(".accordion").find("p").not(dropDown).slideUp(300);
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
        } else {
            $(this)
                .closest(".accordion")
                .find("a.active")
                .removeClass("active");
            $(this).addClass("active");
        }
        dropDown.stop(false, true).slideToggle(300);
        j.preventDefault();
    });

    // Back to top
    $("body").append(
        '<div id="toTop" class="back-to-top-btn"><i class="icofont-dotted-up"></i></div>'
    );
    $(window).scroll(function () {
        if ($(this).scrollTop() != 0) {
            $("#toTop").fadeIn();
        } else {
            $("#toTop").fadeOut();
        }
    });
    $("#toTop").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, 900);
        return false;
    });
})(jQuery);
